import { useState, isValidElement } from 'react';
import { Accordion } from 'react-accessible-accordion';
import slugify from 'slugify';

// types
import type { AccordionPayload } from '../content-elements-payload';

// components
import { Title } from '../Title/Title';
import { AccordionContent } from './AccordionContent';
import { ContentElement } from '../ContentElement';
import { Accordion as GDDSAccordion } from 'components/ContentElementsGdds/Accordion/accordion';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { trackingOnChange } from 'utils/trackAccordion';
import { useSectionId } from 'utils/hooks/use-section-id';
import { isEmpty } from 'utils/is-empty';
import { TitleFormats } from '../Title/title.types';
import { useGroup } from 'utils/hooks/use-group';

export function AccordionContainer({
  title,
  anchor,
  subtitle,
  items,
  openMode,
  contentIndex,
  titleInAnchor,
  catalogName,
  isSectionReference,
  backgroundColor,
}: Readonly<Omit<AccordionPayload, 'titleInAnchor'> & { titleInAnchor: boolean }>) {
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);
  const track = useTracking();
  const { trackClick } = useTracking2();
  const isGroup = useGroup();
  const [openAccordions, setOpenAccordions] = useState([]);

  if (isEmpty(items)) {
    return null;
  }

  if (isGroup) {
    return (
      <GDDSAccordion
        title={title}
        subtitle={subtitle}
        items={items}
        openMode={openMode}
        contentIndex={contentIndex}
        catalogName={catalogName}
        isSectionReference={isSectionReference}
        backgroundColor={backgroundColor}
      />
    );
  }

  const preOpenItems = items
    .filter((_, index) => openMode === 'all' || (index === 0 && openMode === 'first'))
    .map((item, index) => `${index}-${slugify(item.title)}`);

  return (
    <div
      className="grid-container"
      id={sectionId}
      data-preview-id={catalogName && !isSectionReference ? `#${catalogName}` : undefined}
    >
      <Title
        title={title}
        subtitle={subtitle}
        Format={TitleFormats.h2}
        titlePreviewId="#st_title"
        subtitlePreviewId="#st_subtitle"
        isSectionReference={isSectionReference}
        formatClassName="h3"
      />
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="c-accordion"
        preExpanded={preOpenItems}
        onChange={(uuid) => {
          trackingOnChange(track, setOpenAccordions, openAccordions, undefined, uuid);
          trackClick({
            click_intent: 'more_info',
            click_element: 'accordion',
          });
        }}
      >
        {items.map((item, index) => {
          const content = isValidElement(item.content)
            ? item.content
            : item.content
                .filter((subitem) => subitem !== null)
                .map((subitem, listIndex) => (
                  <div
                    key={`${listIndex}-${subitem.type}-${subitem.previewId}`}
                    data-preview-id={!isSectionReference ? `#${listIndex}` : undefined}
                  >
                    <ContentElement
                      // coding guideline exception: we spread all server props to the component
                      {...subitem}
                      contentIndex={listIndex}
                      contentLength={items.length}
                      isSectionReference={isSectionReference}
                      isChild
                    />
                  </div>
                ));

          return (
            <div
              key={`${index}-${item.title}`}
              data-preview-id={!isSectionReference ? `#${index}` : undefined}
            >
              <AccordionContent
                index={index}
                uuid={`${index}-${slugify(item.title)}`}
                title={item.title}
                content={content}
                catalogName={item.catalogName}
                isSectionReference={isSectionReference}
              />
            </div>
          );
        })}
      </Accordion>
    </div>
  );
}
