import { isValidElement } from 'react';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// styles
import styles from '../OnlineCatalogAssets/online-catalog-assets.module.scss';

// components
import { InnerHtml } from '../../InnerHtml/inner-html';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface AccordionContentProps {
  uuid: string;
  title: string;
  content: React.ReactNode;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
  catalogName?: string;
  sourceComponent?: string;
  backgroundColor?: string;
  index: number;
  activeIndex?: number;
  isOKV?: boolean;
  isSectionReference?: boolean;
}

export function AccordionContent({
  uuid,
  title,
  content,
  handleClick,
  catalogName,
  sourceComponent,
  backgroundColor,
  index,
  activeIndex,
  isOKV = false,
  isSectionReference = false,
}: Readonly<AccordionContentProps>) {
  const translate = useTranslationFunction();
  const AccordionTitle = isValidElement(title) ? (
    title
  ) : (
    <>
      <InnerHtml
        as={isOKV ? 'span' : 'h3'}
        className="auto cell h6"
        content={title}
        previewId={
          sourceComponent === 'countrySwitch' || sourceComponent === 'faq-accordion'
            ? undefined
            : '#st_title'
        }
        isSectionReference={isSectionReference}
      />
      <button type="button" className="large-1" aria-label={translate('web20_accordion_toggle')} />
    </>
  );

  return (
    <AccordionItem
      className={classNameBuilder(
        'c-accordion__item',
        backgroundColor && activeIndex == index && styles[`${backgroundColor}Background`],
      )}
      uuid={uuid}
      onClick={handleClick}
    >
      <AccordionItemHeading>
        <AccordionItemButton
          className={classNameBuilder(
            'c-accordion__title grid-x',
            isOKV && styles.okvAccordionTitle,
          )}
          data-index={index}
        >
          {AccordionTitle}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="c-accordion__body">
        <div className="c-accordion__content">
          <InlineEdit
            previewId={catalogName ? `#${catalogName}` : undefined}
            isSectionReference={isSectionReference}
          >
            {content}
          </InlineEdit>
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}
